<template>
  <div class="main__pages">
    <div v-if="!mounted" class="full__screen">
      <app-loader></app-loader>
    </div>
    <div v-else class="main__content">
      <div class="main__orders">
        <add-order
          :status="userData.status"
          @addNewOrderDrop="addNewOrder"
          @getSecondModal="getSecondModal"
        ></add-order>
      </div>
      <div class="main__pages--fixed" style="height: 82px">
        <div class="main__filters">
          <filter-block
            :filterDateRange="filterDateRange"
            @changeFilterSearch="changeFilterSearch"
            @changeFilterDate="changeFilterDate"
          ></filter-block>
          <tag-block
            tabType="payments"
            :today="0"
            :tomorrow="0"
            :countAll="countAll"
            :countToday="countNotPayed.length"
            :countTommorow="countPayed.length"
            @changeFilterDate="changeFilterDate"
            @changeFilterPayd="changeFilterPayd"
          ></tag-block>
        </div>
      </div>
      <orders-list :payments="filteredList"></orders-list>
    </div>
    <new-order
      v-if="newOrder"
      :newOrderType="newOrderType"
      :currentOrderData="currentOrderData"
      @closeModalOrderHandler="closeModalOrderHandler"
    ></new-order>
    <modal-upload
      :openUploadSecondModal="openUploadSecondModal"
      @getSecondModal="getSecondModal"
    ></modal-upload>
  </div>
</template>

<script>
// Preloader
import AppLoader from "../components/ui/app-loader";
// Components
import FilterBlock from "../components/order/filter-block";
import TagBlock from "../components/order/tag-block";
import NewOrder from "../components/order/new-order";
import OrdersList from "../components/payments/payments-list";
import AddOrder from "../components/main/add-order";
import ModalUpload from "../components/order/modal-upload";
// PaymentStatus
import { paymentStatus } from "../data/payments-status";
// Store
import { mapState, mapActions } from "vuex";

export default {
  name: "Payments",
  components: {
    AppLoader,
    NewOrder,
    FilterBlock,
    TagBlock,
    OrdersList,
    AddOrder,
    ModalUpload
  },
  data() {
    return {
      mounted: false,
      newOrder: false,
      newOrderType: "",
      filterDateRange: [],
      filterSearch: "",
      filterPayd: 0,
      rightStatus: [5, 6, 7, 8, 9, 10],
      payments: [],
      mnths: [
        "января",
        "февраля",
        "марта",
        "апреля",
        "мая",
        "июня",
        "июля",
        "августа",
        "сентября",
        "октября",
        "ноября",
        "декабря"
      ],
      currentOrderData: {},
      openUploadSecondModal: false
    };
  },
  created() {
    this.getPayments();
    this.setActivePage();
  },
  mounted() {
    setTimeout(() => {
      this.mounted = true;
    }, 200);
  },
  computed: {
    ...mapState(["page", "token", "userData"]),
    // pageData() {
    //   const pageData = this.page.filter(
    //     (item) => item.name === this.activePageView
    //   );
    //   return pageData[0];
    // },
    // Вывод количества всех заказов
    countAll() {
      let count = this.paymentStatusList.length;
      if (this.payments.length > 1000) {
        count = "1000+";
      }
      return count;
    },
    // Массив счетов
    paymentTempStatusList() {
      this.payments.map((item) => {
        // Статус счёта
        const i = +item.status - 1;
        item.paymentStatusData = {
          color: `order__status--${paymentStatus[i].color}`,
          name: paymentStatus[i].name
        };
        // Статус оплаты для фильтрации
        item.payment = 1;
        if (i === 5) {
          item.payment = 2;
        } else if (i === 6) {
          item.payment = 3;
        }
        // Форматирование даты для фильтрации (ДАТА ВЫСТАВЛЕНИЯ СЧЁТА)
        item.formatedDate = new Date(item.approveDate).getTime();
        // Дата для отображения (ДАТА ВЫСТАВЛЕНИЯ СЧЁТА)
        item.dateToView = new Date(item.approveDate).toLocaleDateString();
        // Дата для печатной формы (ДАТА ВЫСТАВЛЕНИЯ СЧЁТА)
        item.scoreDateDay = new Date(item.approveDate).getDate();
        item.scoreDateMonth = this.mnths[new Date(item.approveDate).getMonth()];
        item.scoreDateYear = new Date(item.approveDate).getFullYear();
        // Форматирование номера счёта
        // item.formatedScore = item.invoiceNumber;
        if (item.invoiceNumber) {
          item.formatedScore = +item.invoiceNumber.replace(/[^0-9]/g, "");
        }
        // Дата создания реестра
        item.dateCreateRegistr = new Date(
          item.approveDate
        ).toLocaleDateString();
      });
      return this.payments;
    },
    // Убираем не нужные и удалённые заказы
    paymentStatusList() {
      return this.paymentTempStatusList.filter((item) =>
        this.rightStatus.includes(+item.status)
      );
    },
    // Список отсортированных счетов
    filteredList() {
      const filtered = this.paymentStatusList
        .filter((item) => {
          return (
            this.filterDateRange.length === 0 ||
            (this.filterDateRange[0] === null &&
              this.filterDateRange[1] === null) ||
            (item.formatedDate >= this.filterDateRange[0] &&
              item.formatedDate <= this.filterDateRange[1])
          );
        })
        .filter((item) => {
          return (
            this.filterSearch === "" ||
            this.filterSearch == item.invoiceNumber ||
            item.invoiceNumber
              .toLowerCase()
              .indexOf(this.filterSearch.toLowerCase()) !== -1
          );
        })
        .filter((item) => {
          if (this.filterPayd === 0) {
            return item.payment > 0;
          } else if (this.filterPayd === 1) {
            return item.payment === 1;
          } else if (this.filterPayd === 2) {
            return item.payment >= 2;
          }
        });
      return filtered.sort((a, b) => {
        return b.formatedDate - a.formatedDate;
      });
    },
    // Неоплаченные счета
    countNotPayed() {
      return this.paymentStatusList.filter((item) => item.payment == 1);
    },
    // Оплаченные счета
    countPayed() {
      return this.paymentStatusList.filter((item) => item.payment >= 2);
    }
  },
  methods: {
    // Store
    ...mapActions(["CHANGE_ACTIVE_PAGE", "REFRESH_TOKEN_HANDLER"]),
    // Текущая активная страница
    setActivePage() {
      this.CHANGE_ACTIVE_PAGE("payments");
    },
    // Получение счетов из базы
    async getPayments() {
      await this.axios
        .get("/api/client/registers/get", {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          this.payments = response.data.response;
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.getPayments();
            });
          } else {
            console.error("Ошибка в получении refreshToken" + e.message);
          }
        });
    },
    // Создание нового заказа
    addNewOrder(value) {
      this.newOrder = true;
      this.newOrderType = value;
    },
    // Закрытие модального окна, очистка данных
    closeModalOrderHandler() {
      this.newOrder = false;
      this.newOrderType = "";
    },
    changeFilterSearch(value) {
      this.filterSearch = value;
    },
    changeFilterDate(value) {
      this.filterDateRange = value;
    },
    changeFilterPayd(value) {
      this.filterPayd = value;
    },
    // Закрываем второе окно
    getSecondModal(value) {
      this.openUploadSecondModal = value;
    }
  },
  watch: {
    filterDateRange() {
      if (
        this.filterDateRange[0] === null &&
        this.filterDateRange[1] === null
      ) {
        this.ordersTag = ["all"];
      }
    },
    filteredList() {
      window.scrollTo(0, 0);
    }
  }
};
</script>
