export const paymentStatus = [
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "green-grey", name: "Оплачен" },
  { color: "green", name: "Оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Не оплачен" },
  { color: "red", name: "Дебеторка" }
];
