<template>
  <div id="basic" slot="pdf-content">
    <section class="pdf-item">
      <table class="table-payd">
        <tbody>
          <tr>
            <td
              style="
                width: 294pt;
                vertical-align: top;
                height: 30pt;
                border-right: none;
                border-bottom: none;
              "
              rowspan="2"
              colspan="2"
            >
              <div style="position: relative; height: 28pt">
                <div style="text-transform: uppercase">
                  {{ glpData.bankName }}
                </div>
                <div style="font-size: 9pt; position: absolute; bottom: 0">
                  Банк получателя
                </div>
              </div>
            </td>
            <td
              style="
                width: 40pt;
                height: 10pt;
                border-right: none;
                border-bottom: none;
              "
            >
              БИК
            </td>
            <td
              style="
                width: 261pt;
                height: 20pt;
                vertical-align: top;
                border-bottom: none;
              "
              rowspan="2"
            >
              {{ glpData.bic }}<br />
              {{ glpData.corScore }}
            </td>
          </tr>
          <tr>
            <td
              style="
                width: 40pt;
                height: 20pt;
                vertical-align: top;
                border-right: none;
                border-bottom: none;
              "
            >
              Сч. №
            </td>
          </tr>
          <tr>
            <td
              style="
                width: 147pt;
                height: 10pt;
                border-right: none;
                border-bottom: none;
              "
            >
              ИНН {{ glpData.inn }}
            </td>
            <td
              style="
                width: 147pt;
                height: 10pt;
                border-right: none;
                border-bottom: none;
              "
            >
              КПП {{ glpData.kpp }}
            </td>
            <td
              style="
                width: 40pt;
                height: 40pt;
                vertical-align: top;
                border-right: none;
              "
              rowspan="2"
            >
              Сч. №
            </td>
            <td
              style="width: 261pt; height: 40pt; vertical-align: top"
              rowspan="2"
            >
              {{ glpData.paymentScore }}
            </td>
          </tr>
          <tr>
            <td
              style="
                width: 294px;
                height: 30pt;
                vertical-align: top;
                border-right: none;
              "
              colspan="2"
            >
              <div style="position: relative; height: 28pt">
                <div>ООО "Джи Эл Пи"</div>
                <div style="font-size: 9pt; position: absolute; bottom: 0">
                  Получатель
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style="
          color: #000000;
          text-align: left;
          font-size: 11pt;
          font-weight: bold;
          padding-bottom: 10pt;
          border-bottom: 2pt solid #000;
          margin-bottom: 10pt;
        "
      >
        Счет на оплату № {{ registrData.formatedScore }} от
        {{ registrData.scoreDateDay }} {{ registrData.scoreDateMonth }}
        {{ registrData.scoreDateYear }} г.
      </div>
      <table class="table-payd" style="border: none; margin-bottom: 0">
        <tbody>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Поставщик:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              ООО "Джи Эл Пи", ИНН {{ glpData.inn }}, КПП {{ glpData.kpp }},
              {{ glpData.legalAddress }}
            </td>
          </tr>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Покупатель:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              {{ registrData.fullName }}, ИНН {{ registrData.inn }}, КПП
              {{ registrData.kpp }}, {{ registrData.officialAddress }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <thead>
          <tr>
            <th class="bolder" style="border-right: none; border-bottom: none">
              №
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Товары (работы, услуги)
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Кол-во
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Ед.
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Цена
            </th>
            <th class="bolder" style="border-bottom: none">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="payd-info text-center"
              style="border-right: none; width: 30pt"
            >
              1
            </td>
            <td class="payd-info" style="border-right: none; width: 300pt">
              Транспортные услуги с {{ registrData.registrFirstOrder }} по
              {{ registrData.registrLastOrder }} согласно договору №
              {{ registrData.registrContract }} по реестру №
              {{ registrData.registrId }} от
              {{ registrData.dateCreateRegistr }}
            </td>
            <td class="payd-info text-right" style="border-right: none">1</td>
            <td class="payd-info" style="border-right: none">шт</td>
            <td class="payd-info text-right" style="border-right: none">
              {{ registrData.sum | rounding }}
            </td>
            <td class="payd-info text-right">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Итого:
            </td>
            <td
              class="text-right bolder-sum"
              style="border-top: none; border-bottom: none"
            >
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Без налога (НДС):
            </td>
            <td class="text-right bolder-sum" style="border-bottom: none">-</td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Всего к оплате:
            </td>
            <td class="text-right bolder-sum">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <tbody>
          <tr>
            <td style="border: none">
              Всего наименований 1, на сумму
              {{ registrData.sum | rounding }} руб.
            </td>
          </tr>
          <tr>
            <td class="bolder" style="text-align: left; border: none">
              <div class="textNum">{{ getTextFromSum }}</div>
            </td>
          </tr>
        </tbody>
      </table>
      <div style="border-bottom: 2pt solid #000"></div>
      <table class="table-payd" style="border: none">
        <tbody>
          <tr>
            <td style="padding: 0; border: none">
              <div
                class="d-flex d-content-start d-align-bottom"
                style="padding-top: 20pt"
              >
                <div
                  class="d-flex d-content-end d-align-bottom"
                  style="width: 235pt"
                >
                  <div
                    class="d-flex d-content-start d-align-top"
                    style="width: 100pt; height: 100%"
                  >
                    <div
                      style="height: 23pt; font-size: 10pt; font-weight: bold"
                    >
                      Руководитель
                    </div>
                  </div>
                  <div style="width: 135pt; text-align: center">
                    <div
                      style="
                        width: 90%;
                        margin: 0 auto;
                        font-size: 10pt;
                        font-weight: bold;
                        border-bottom: 1px solid #000;
                      "
                    >
                      Генеральный директор
                    </div>
                    <div class="text-center; font-size: 9pt;">должность</div>
                  </div>
                </div>
                <div
                  style="
                    width: 155pt;
                    text-align: center;
                    position: relative;
                    z-index: 1;
                  "
                >
                  <img
                    style="
                      position: absolute;
                      z-index: 10;
                      width: 110pt;
                      top: -40pt;
                      left: -80pt;
                      transform: rotate(345deg);
                    "
                    src="../../assets/images/signature/stamp-GLP-clear-all.png"
                    alt="Печать"
                  />
                  <img
                    style="
                      position: absolute;
                      z-index: 2;
                      width: 80pt;
                      top: -30pt;
                      left: 30pt;
                    "
                    src="../../assets/images/signature/signature_dir.png"
                    alt="Подпись"
                  />
                  <div
                    style="
                      width: 90%;
                      margin: 0 auto;
                      border-bottom: 1px solid #000;
                    "
                  ></div>
                  <div class="text-center; font-size: 9pt;">подпись</div>
                </div>
                <div style="width: 155pt; text-align: center">
                  <div
                    style="
                      width: 90%;
                      margin: 0 auto;
                      font-size: 16px;
                      font-weight: bold;
                      border-bottom: 1px solid #000;
                    "
                  >
                    {{ generalManager }}
                  </div>
                  <div class="text-center; font-size: 9pt;">
                    расшифровка подписи
                  </div>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td style="padding: 0; border: none">
              <div
                class="d-flex d-content-start d-align-bottom"
                style="padding-top: 10pt"
              >
                <div
                  class="d-flex d-content-end d-align-bottom"
                  style="width: 235pt"
                >
                  <div
                    class="d-flex d-content-start d-align-top"
                    style="width: 235pt; height: 100%"
                  >
                    <div
                      style="height: 23pt; font-size: 10pt; font-weight: bold"
                    >
                      Главный бухгалтер
                    </div>
                  </div>
                </div>
                <div
                  style="
                    width: 155pt;
                    text-align: center;
                    position: relative;
                    z-index: 1;
                  "
                >
                  <img
                    style="
                      position: absolute;
                      z-index: 2;
                      width: 130pt;
                      top: -30pt;
                      left: 30pt;
                    "
                    src="../../assets/images/signature/signature_buch.png"
                    alt="Подпись"
                  />
                  <div
                    style="
                      width: 90%;
                      margin: 0 auto;
                      border-bottom: 1px solid #000;
                      position: relative;
                      z-index: 1;
                    "
                  ></div>
                  <div class="text-center; font-size: 9pt;">подпись</div>
                </div>
                <div style="width: 155pt; text-align: center">
                  <div
                    style="
                      width: 90%;
                      margin: 0 auto;
                      font-size: 16px;
                      font-weight: bold;
                      border-bottom: 1px solid #000;
                    "
                  >
                    {{ staffFinance }}
                  </div>
                  <div class="text-center; font-size: 9pt;">
                    расшифровка подписи
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div class="html2pdf__page-break" />
    <section class="pdf-item" style="margin-top: 30pt; padding-bottom: 50pt">
      <div
        style="
          color: #000000;
          text-align: left;
          font-size: 11pt;
          font-weight: bold;
          padding-bottom: 10pt;
          border-bottom: 2pt solid #000;
          margin-bottom: 10pt;
        "
      >
        Акт № {{ registrData.formatedScore }} от {{ registrData.scoreDateDay }}
        {{ registrData.scoreDateMonth }} {{ registrData.scoreDateYear }} г.
      </div>
      <table class="table-payd" style="border: none; margin-bottom: 0">
        <tbody>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Исполнитель:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              ООО "Джи Эл Пи", ИНН {{ glpData.inn }}, КПП {{ glpData.kpp }},
              {{ glpData.legalAddress }}
            </td>
          </tr>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Заказчик:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              {{ registrData.fullName }}, ИНН {{ registrData.inn }}, КПП
              {{ registrData.kpp }}, {{ registrData.officialAddress }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <thead>
          <tr>
            <th class="bolder" style="border-right: none; border-bottom: none">
              №
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Наименование работ, услуг
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Кол-во
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Ед.
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Цена
            </th>
            <th class="bolder" style="border-bottom: none">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="payd-info text-center"
              style="border-right: none; width: 30pt"
            >
              1
            </td>
            <td class="payd-info" style="border-right: none; width: 300pt">
              Транспортные услуги с {{ registrData.registrFirstOrder }} по
              {{ registrData.registrLastOrder }} согласно договору №
              {{ registrData.registrContract }} по реестру №
              {{ registrData.registrId }} от
              {{ registrData.dateCreateRegistr }}
            </td>
            <td class="payd-info text-right" style="border-right: none">1</td>
            <td class="payd-info" style="border-right: none">шт</td>
            <td class="payd-info text-right" style="border-right: none">
              {{ registrData.sum | rounding }}
            </td>
            <td class="payd-info text-right">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Итого:
            </td>
            <td
              class="text-right bolder-sum"
              style="border-top: none; border-bottom: none"
            >
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Без налога (НДС):
            </td>
            <td class="text-right bolder-sum" style="border-bottom: none">-</td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Всего к оплате:
            </td>
            <td class="text-right bolder-sum">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <tbody>
          <tr>
            <td style="border: none">
              Всего наименований 1, на сумму
              {{ registrData.sum | rounding }} руб.
            </td>
          </tr>
          <tr>
            <td class="bolder" style="text-align: left; border: none">
              <div class="textNum">{{ getTextFromSum }}</div>
            </td>
          </tr>
          <tr>
            <td style="text-align: left; border: none">
              Вышеперечисленные услуги выполнены полностью и в срок. Заказчик
              претензий по объему, качеству и срокам оказания услуг не имеет.
            </td>
          </tr>
        </tbody>
      </table>
      <div style="border-bottom: 2pt solid #000"></div>
      <table class="table-payd" style="border: none; margin-top: 10pt">
        <tbody>
          <tr>
            <td
              style="padding: 0; border: none; width: 50%; vertical-align: top"
            >
              <div
                style="d-flex align-items-center justify-content-center; position: relative"
              >
                <strong>Исполнитель</strong>
                ______________________________________
                <small style="position: absolute; top: 0; right: 30pt"
                  >Генеральный директор</small
                ><small style="position: absolute; top: 11pt; right: 60pt"
                  >Гулинчук Д. М.</small
                >
                <div
                  style="
                    width: 155pt;
                    text-align: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                  "
                >
                  <img
                    style="
                      position: absolute;
                      z-index: 10;
                      width: 110pt;
                      top: -30pt;
                      left: -80pt;
                      transform: rotate(345deg);
                    "
                    src="../../assets/images/signature/stamp-GLP-clear-all.png"
                    alt="Печать"
                  />
                  <img
                    style="
                      position: absolute;
                      z-index: 2;
                      width: 80pt;
                      top: -20pt;
                      left: -30pt;
                    "
                    src="../../assets/images/signature/signature_dir.png"
                    alt="Подпись"
                  />
                </div>
              </div>
            </td>
            <td
              style="padding: 0; border: none; width: 50%; vertical-align: top"
            >
              <div style="d-flex align-items-center justify-content-center">
                <strong>Заказчик</strong>
                ______________________________________
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
    <div class="html2pdf__page-break" />
    <section class="pdf-item" style="margin-top: 30pt; padding-bottom: 50pt">
      <div
        style="
          color: #000000;
          text-align: left;
          font-size: 11pt;
          font-weight: bold;
          padding-bottom: 10pt;
          border-bottom: 2pt solid #000;
          margin-bottom: 10pt;
        "
      >
        Акт № {{ registrData.formatedScore }} от {{ registrData.scoreDateDay }}
        {{ registrData.scoreDateMonth }} {{ registrData.scoreDateYear }} г.
      </div>
      <table class="table-payd" style="border: none; margin-bottom: 0">
        <tbody>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Исполнитель:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              ООО "Джи Эл Пи", ИНН {{ glpData.inn }}, КПП {{ glpData.kpp }},
              {{ glpData.legalAddress }}
            </td>
          </tr>
          <tr>
            <td
              style="
                border: none;
                width: 90pt;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              Заказчик:
            </td>
            <td
              style="
                border: none;
                font-weight: bold;
                padding: 2pt 2pt 10pt 2pt !important;
              "
            >
              {{ registrData.fullName }}, ИНН {{ registrData.inn }}, КПП
              {{ registrData.kpp }}, {{ registrData.officialAddress }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <thead>
          <tr>
            <th class="bolder" style="border-right: none; border-bottom: none">
              №
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Наименование работ, услуг
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Кол-во
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Ед.
            </th>
            <th class="bolder" style="border-right: none; border-bottom: none">
              Цена
            </th>
            <th class="bolder" style="border-bottom: none">Сумма</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="payd-info text-center"
              style="border-right: none; width: 30pt"
            >
              1
            </td>
            <td class="payd-info" style="border-right: none; width: 300pt">
              Транспортные услуги с {{ registrData.registrFirstOrder }} по
              {{ registrData.registrLastOrder }} согласно договору №
              {{ registrData.registrContract }} по реестру №
              {{ registrData.registrId }} от
              {{ registrData.dateCreateRegistr }}
            </td>
            <td class="payd-info text-right" style="border-right: none">1</td>
            <td class="payd-info" style="border-right: none">шт</td>
            <td class="payd-info text-right" style="border-right: none">
              {{ registrData.sum | rounding }}
            </td>
            <td class="payd-info text-right">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Итого:
            </td>
            <td
              class="text-right bolder-sum"
              style="border-top: none; border-bottom: none"
            >
              {{ registrData.sum | rounding }}
            </td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Без налога (НДС):
            </td>
            <td class="text-right bolder-sum" style="border-bottom: none">-</td>
          </tr>
          <tr>
            <td style="border: none" colspan="5" class="text-right bolder">
              Всего к оплате:
            </td>
            <td class="text-right bolder-sum">
              {{ registrData.sum | rounding }}
            </td>
          </tr>
        </tbody>
      </table>
      <table class="table-payd" style="border: none; margin-bottom: 10px">
        <tbody>
          <tr>
            <td style="border: none">
              Всего наименований 1, на сумму
              {{ registrData.sum | rounding }} руб.
            </td>
          </tr>
          <tr>
            <td class="bolder" style="text-align: left; border: none">
              <div class="textNum">
                {{ getTextFromSum }}
              </div>
            </td>
          </tr>
          <tr>
            <td style="text-align: left; border: none">
              Вышеперечисленные услуги выполнены полностью и в срок. Заказчик
              претензий по объему, качеству и срокам оказания услуг не имеет.
            </td>
          </tr>
        </tbody>
      </table>
      <div style="border-bottom: 2pt solid #000"></div>
      <table class="table-payd" style="border: none; margin-top: 10pt">
        <tbody>
          <tr>
            <td
              style="padding: 0; border: none; width: 50%; vertical-align: top"
            >
              <div
                style="d-flex align-items-center justify-content-center; position: relative"
              >
                <strong>Исполнитель</strong>
                ______________________________________
                <small style="position: absolute; top: 0; right: 30pt"
                  >Генеральный директор</small
                ><small style="position: absolute; top: 11pt; right: 60pt"
                  >Гулинчук Д. М.</small
                >
                <div
                  style="
                    width: 155pt;
                    text-align: center;
                    position: absolute;
                    right: 0;
                    top: 0;
                    z-index: 1;
                  "
                >
                  <img
                    style="
                      position: absolute;
                      z-index: 10;
                      width: 110pt;
                      top: -30pt;
                      left: -80pt;
                      transform: rotate(345deg);
                    "
                    src="../../assets/images/signature/stamp-GLP-clear-all.png"
                    alt="Печать"
                  />
                  <img
                    style="
                      position: absolute;
                      z-index: 2;
                      width: 80pt;
                      top: -20pt;
                      left: -30pt;
                    "
                    src="../../assets/images/signature/signature_dir.png"
                    alt="Подпись"
                  />
                </div>
              </div>
            </td>
            <td
              style="padding: 0; border: none; width: 50%; vertical-align: top"
            >
              <div style="d-flex align-items-center justify-content-center">
                <strong>Заказчик</strong>
                ______________________________________
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</template>

<script>
// Перевод чисел в текст
import { rubles } from "rubles";
// Store
import { mapState } from "vuex";

export default {
  name: "PrintScore",
  props: { registrData: Object },
  // data() {
  //   return {
  //     registrContract: "50004084762 от 25.03.2022"
  //   };
  // },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else {
            tail = "." + arr[1];
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  computed: {
    ...mapState(["serverPath"]),
    glpData() {
      if (Object.keys(this.registrData).length !== 0) {
        return this.registrData.settingsCompany[0];
      } else {
        return "";
      }
    },
    generalManager() {
      if (Object.keys(this.registrData).length !== 0) {
        return this.getFormatedFullname(
          this.registrData.settingsCompany[0].generalManager
        );
      } else {
        return "";
      }
    },
    staffFinance() {
      if (this.registrData.StaffFinance) {
        return this.getFormatedFullname(this.registrData.StaffFinance.fullName);
      } else {
        return "";
      }
    },
    getTextFromSum() {
      return rubles(this.registrData.sum);
    }
  },
  methods: {
    getFormatedFullname(value) {
      const temp = value.split(" ");
      return (
        temp[0] + " " + temp[1].slice(0, 1) + ". " + temp[2].slice(0, 1) + "."
      );
    }
  }
};
</script>

<style scoped>
body {
  font-size: 9pt;
  font-family: "Times New Roman", sans-serif;
  font-style: normal;
  color: #000000;
  text-align: justify;
  margin: 0;
  padding: 0;
}

#payd {
  font-size: 10pt !important;
  font-family: Arial, Helvetica, sans-serif !important;
}

#basic {
  box-sizing: border-box;
  margin-top: 30pt;
  width: 595.28pt;
  padding: 0pt 28pt 30pt 28pt;
  position: relative;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 30px;
}

table th,
table td {
  border: 1pt solid #000;
  font-size: 9pt;
  font-family: "Times New Roman", sans-serif;
  font-style: normal;
  color: #000000;
  text-align: center;
  padding: 5pt;
}

.table-payd th,
.table-payd td {
  text-align: left;
  font-size: 9pt !important;
  font-family: Arial, Helvetica, sans-serif !important;
  padding: 2pt !important;
}

.table-payd .bolder {
  font-size: 9pt !important;
  font-weight: bold !important;
  text-align: center;
}

.table-payd .bolder-sum {
  font-size: 10pt !important;
  font-weight: bold !important;
  text-align: center;
}

.table-payd .payd-info {
  height: 30pt;
  vertical-align: top;
}

.table-payd .textNum {
  display: block !important;
  margin-bottom: 10pt;
}

/* .textNum:first-letter {
  text-transform: uppercase !important;
} */

h1,
h2 {
  font-size: 9pt;
}
h1,
h3 {
  margin: 9pt 0;
  text-align: center;
}
h2 {
  margin: 9pt 0;
  text-align: left;
}

/* Display */
.d-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.d-content-start {
  justify-content: flex-start !important;
}
.d-content-between {
  justify-content: space-between !important;
}
.d-content-end {
  justify-content: flex-end !important;
}
.d-align-top {
  align-items: flex-start !important;
}
.d-align-bottom {
  align-items: flex-end !important;
}
.d-align-between {
  align-items: space-between !important;
}

/* Align */
.text-center {
  text-align: center !important;
}

.text-right {
  text-align: right !important;
}

/* List */
ul {
  list-style: none;
  margin-left: -38px;
}
</style>
