<template>
  <div class="main__table payments">
    <table>
      <thead>
        <tr>
          <th style="width: 135px">Статус счета</th>
          <th style="width: 120px" class="td__left">Дата<br />счёта</th>
          <th style="width: 120px" class="td__left">Номер<br />счёта</th>
          <th style="width: 120px" class="td__left">Сумма<br />счёта</th>
          <th style="width: 120px" class="td__left">Оплаченная<br />сумма</th>
          <th style="width: 120px" class="td__left">Остаток<br />по выплате</th>
          <th style="width: 140px" class="td__left">
            Дата получения<br />оригинала
          </th>
          <th style="width: 100px" class="td__left">Отсрочка<br />платежа</th>
          <th style="width: 120px" class="td__left">
            Плановая<br />дата оплаты
          </th>
          <th style="width: 80px" class="td__left">Скачать<br />счёт</th>
          <th class="td__left">Скачать<br />реестр</th>
        </tr>
      </thead>
      <tbody v-if="paymentsList.length > 0">
        <tr
          v-for="(payment, idx) in paymentsList"
          :key="payment.id"
          :class="idx % 2 ? '' : ' striple'"
          class="pointer"
        >
          <td style="width: 135px">
            <div
              class="order__status"
              :class="payment.paymentStatusData.color"
              style="justify-content: center !important; padding: 0"
            >
              {{ payment.paymentStatusData.name }}
            </div>
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.dateToView }}
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.formatedScore }}
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.sum | rounding }}
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.sumPaid | rounding }}
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.sumDiff | rounding }}
          </td>
          <td style="width: 140px" class="td__left">
            {{ payment.scoreDate }}
          </td>
          <td style="width: 100px" class="td__left">
            {{ userData.deferredPayment }}
          </td>
          <td style="width: 120px" class="td__left">
            {{ payment.dateMostPayd }}
          </td>
          <td style="width: 80px" class="td__left">
            <div @click="createPDF(payment.id)">
              <svg
                class="hover__stroke"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 10L12 15L17 10"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 15V3"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </td>
          <td class="td__left">
            <div @click="openXSL(payment.id, payment.formatedScore)">
              <svg
                class="hover__stroke"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 10L12 15L17 10"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 15V3"
                  stroke="#58595B"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="11" class="empty__data">
            <span>Записей, удовлетворяющих условиям поиска, не найдено</span>
          </td>
        </tr>
      </tbody>
    </table>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="true"
      :preview-modal="false"
      filename="payment"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width=""
      ref="html2Pdf"
    >
      <section slot="pdf-content">
        <print-score :registrData="registrData"></print-score>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
// PDF
import VueHtml2pdf from "vue-html2pdf";
// Components
import PrintScore from "./print-score";
// Store
import { mapActions, mapState } from "vuex";

export default {
  name: "PaymentsList",
  props: {
    payments: Array
  },
  components: { VueHtml2pdf, PrintScore },
  mounted() {
    this.getHeightPage();
  },
  data() {
    return {
      registrData: {},
      fileName: "реестр-к-счёту"
    };
  },
  filters: {
    rounding(value, notTail = 0) {
      if (!value || value === "" || value == "0") {
        return 0;
      } else {
        let tail = "";
        value = value.toString();
        if (value.includes(".")) {
          const arr = value.split(".");
          value = arr[0];
          if (arr[1].length === 1) {
            tail = "." + arr[1] + "0";
          } else {
            tail = "." + arr[1];
          }
        } else {
          tail = ".00";
        }
        value = value.replace(/\s/g, "");
        if (notTail) {
          tail = "";
        }
        if (value.length > 3) {
          let right = value.slice(-3),
            left = value.slice(0, -3),
            leftOnce = "";
          if (left.length > 3) {
            leftOnce = left.slice(0, -3);
            left = left.slice(-3);
          }
          if (leftOnce) {
            return leftOnce + " " + left + " " + right + tail;
          } else {
            return left + " " + right + tail;
          }
        } else {
          return value + tail;
        }
      }
    }
  },
  computed: {
    ...mapState(["serverPath", "userData", "token"]),
    getDateNow() {
      return new Date(Date.now()).getTime();
    },
    paymentsList() {
      this.payments.map((item) => {
        // Invoice Date
        item.scoreDate = "";
        item.dateMostPayd = "";
        if (item.dateOtpravki && item.dateOtpravki !== "01.01.0001 0:00:00") {
          item.scoreDate = item.dateOtpravki.substr(0, 10);
          // Date Client Pay
          if (this.userData.deferredPayment && item.scoreDate) {
            const newDate = item.scoreDate.split("."),
              newDateTrue = newDate[2] + "-" + newDate[1] + "-" + newDate[0];
            item.dateMostPayd = new Date(
              new Date(newDateTrue).getTime() +
                this.userData.deferredPayment * 86400000
            ).toLocaleDateString();
          }
        }
        item.sumPaid = +item.sumPaid.toFixed(2);
        item.sumDiff = +item.sum - item.sumPaid;
        if (item.sumDiff > 0) {
          item.sumDiff = item.sumDiff.toFixed(2);
        }
      });
      return this.payments;
    }
  },
  methods: {
    ...mapActions(["REFRESH_TOKEN_HANDLER"]),
    // Получение высоты браузера
    getHeightPage(height = 190) {
      document.querySelector("body").style = "overflow-y: hidden";
      const pageHight = +document.documentElement.clientHeight,
        tableFixedHight = pageHight - height;
      document.querySelector(".main__table").style.height =
        tableFixedHight + "px";
      return tableFixedHight;
    },
    // Получение данных по конкретному реестру
    async getRegistrOneData(registrId) {
      await this.axios
        .get(`/api/client/register/get/${registrId}`, {
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          const first = [response.data.response],
            second = [...this.payments.filter((item) => item.id == registrId)],
            third = first.map((item, index) => ({
              ...item,
              ...second[index],
              ...this.userData,
              ...{ registrId: registrId }
            }));
          this.registrData = third[0];
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.getRegistrOneData();
            });
          } else {
            console.error("Ошибка в получении getRegistrOneData" + e.message);
          }
        });
    },
    // Открытие документа в новом окне
    openDocument(value) {
      window.open(value);
    },
    // Создание документа PDF
    createPDF(id) {
      this.getRegistrOneData(id).then(() => {
        this.$refs.html2Pdf.generatePdf();
      });
    },
    // Открываем EXCEL
    async openXSL(registrId, score) {
      await this.axios
        .get(`/api/client/register/xls/${registrId}`, {
          responseType: "blob",
          headers: { Authorization: `Bearer ${this.token}` }
        })
        .then((response) => {
          const blob = new Blob([response.data], {
            type: "application/vnd.ms-excel"
          });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = `${this.fileName}-${score}.xls`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch((e) => {
          const { code, message } = e.response.data;
          if (code === 401 && message === "Expired JWT Token") {
            this.REFRESH_TOKEN_HANDLER().then(() => {
              this.openXSL();
            });
          } else {
            console.error("Ошибка в получении openXSL" + e.message);
          }
        });
    }
  }
};
</script>

<style lang="scss">
.payments {
  table {
    width: 100% !important;
  }

  table th,
  table td {
    color: $color-black !important;
    height: 40px !important;
    padding: 0 15px;
  }

  .td__left {
    padding-left: 16px !important;
    text-align: left !important;
  }
}

.registr__icon {
  @include flex;
  @include flex-content-start;

  div {
    margin-right: 10px;
  }
}
</style>
